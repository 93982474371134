<template>
  <div class="header">
    <v-app-bar :color="whiteLabel.colors.primary" height="90" dark flat>
      <div class="text-center pl-6 pl-md-8 pt-2">
        <v-img
          hidden
          :src="whiteLabel.images.logo"
          heigth="2"
          max-width="60"
          contain
        >
        </v-img>
        <div>Keys</div>
      </div>
      <v-spacer></v-spacer>
			<div v-if="building">{{ building.name ? building.name : building.address }}<br/>
			<span v-if="building.name">{{building.address}}</span></div>

      <v-btn v-if="false" icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      building: "building",
      whiteLabel: "whiteLabel",
    })
  },
  
  methods: {
  },
};
</script>
