import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    building: null,
    key: null,
    whiteLabel: {
      colors: {
        primary: "indigo",
        secondary: "blue",
        fontPrimary: "white",
      },
      images: {
        logo: require("@/assets/logo-tappbell.png"),
        iso: "https://tappbell.com/assets/images/logo.png",
      },
      domains: {
        cloud: "cloud.tappbell.com",
        virtual: "virtual.tappbell.com",
        email: "info@tappbell.com"
      },
      labels: {
        brand: "TappBell",
        tagline: "Cloud",
        website: "www.tappbell.com",
      },
      support: {
        whatsApp: "541150095370",
      },
    },  
  },
  getters: {
    building(state) {
      return state.building;
    },
    key(state) {
      return state.key;
    },
    whiteLabel(state) {
      return state.whiteLabel;
    },    
  },
  mutations: {
    SET_KEY(state, value) {
      if (value) {
        state.key = value.key;
        state.building = value.building;
        state.whiteLabel = value.dealer.white_label;
      } else {
        state.key = null;
        state.building = null;
        //state.whiteLabel = null;
      }
    },  
  },
  actions: {
    async fetchKeysData({ commit }, code) {
      await Vue.axios.get("https://us-central1-tappbellcloud.cloudfunctions.net/getKeysData/" + code)
      .then((response) => {
          if (response.status == 200) {
            commit("SET_KEY", response.data);
          } else {
            commit("SET_KEY", null);
          }
      })
      .catch((error) => {
        console.log('catched error', error);
        commit("SET_KEY", null);
      });
    },
  },
})
