import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: '/invalid',
    name: 'invalid',
    component: () => import(/* webpackChunkName: "keys" */ "../views/Invalid.vue"),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "keys" */ "../views/Error.vue"),
  },
  {
    path: '/open',
    name: 'open',
    component: () => import(/* webpackChunkName: "keys" */ "../views/Open.vue"),
  },  
  {
    path: '/',
    name: 'key',
    component: () => import(/* webpackChunkName: "keys" */ "../views/Key.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;

